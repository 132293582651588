import React from "react";
import "./App.css"; 

function App() {
  return (
    <div className="centered-text">
      <h1>Join Our Evolution</h1>
      <p>Coming Soon...</p>
    </div>
  );
}

export default App;
